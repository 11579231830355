import axios from 'axios';

const api = axios.create({
  baseURL: 'https://manager-demo.goverify.digitalie.dev/api',
  // baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3001/api',
  withCredentials: false,
});

api.defaults.headers = {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
};

export default api;
